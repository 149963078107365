import {
  Button,
  CircularProgress,
  FormControl,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Flex from "styled-flex-component";
import {
  GetInsightsContext,
  GetInsightsContextType,
} from "../../../pages/GetInsights/context/getInsightsContext";
import { classifyDoc, classifyPage } from "../../../api/classify.api";
import { toast } from "react-toastify";
import { Text } from "../../UI/Typography";
import { submit } from "../../../api/grab.api";
import SearchTypeField from "../../UI/Common/SearchTypeField";

interface IItemTypeSelectorProps {
  onSaveSuccess: () => void;
  isSkippable: boolean;
}

const ItemTypeSelector = ({
  onSaveSuccess,
  isSkippable,
}: IItemTypeSelectorProps) => {
  const {
    pageTypeOptions,
    docTypeOptions,
    packetData,
    setBackToPageTrigger,
    backToPageTrigger,
  } = useContext(GetInsightsContext) as GetInsightsContextType;

  const [selectedType, setSelectedType] = useState("placeholder");
  const [initialType, setInitialType] = useState("placeholder");
  const [isSaving, setIsSaving] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [doingAction, setDoingAction] = useState("");
  const [typeOptions, setTypeOptions] = useState<any>([]);
  const [displayedOptions, setDisplayedOptions] = useState<any>([]);
  const [searchKey, setSearchKey] = useState("");

  const getId = (name) => {
    return typeOptions.find((type) => type.name == name)?.type_id;
  };

  useEffect(() => {
    setTypeOptions(packetData.page_id ? pageTypeOptions : docTypeOptions);
    setDisplayedOptions(packetData.page_id ? pageTypeOptions : docTypeOptions);
  }, [pageTypeOptions, docTypeOptions]);

  useEffect(() => {}, [typeOptions]);

  useEffect(() => {
    if (typeOptions) {
      const currType =
        (packetData.page_id
          ? getId(packetData.page_type)
          : getId(packetData.document_type)) ?? "placeholder";

      setSelectedType(currType);
      setInitialType(currType);
    }
  }, [typeOptions]);

  useEffect(() => {
    const filteredTypeOptions = typeOptions.filter((option) =>
      option.name.toLowerCase().includes(searchKey.toLowerCase())
    );
    setDisplayedOptions(filteredTypeOptions);
  }, [searchKey]);

  const perform = async (action) => {
    setIsSaving(true);
    setDoingAction(action);
    try {
      await doSave(selectedType);
      if (action == "submit") await submit(packetData.assignment_id);
      toast.success(
        `Item classification ${action == "save" ? "saved" : "submitted"}.`,
        { autoClose: 1000, closeOnClick: true }
      );
      setIsSaving(false);
      onSaveSuccess();
    } catch (e: any) {
      toast.error(e?.detail ?? "Something went wrong");
      setIsSaving(false);
    }
  };

  async function doSave(classification) {
    try {
      //save page classification
      if (packetData.page_id) {
        await classifyPage(
          packetData.doc_id,
          packetData.page_id,
          classification,
          initialType != "placeholder"
        );
      } else {
        //save doc classification
        await classifyDoc(
          packetData.doc_id,
          classification,
          initialType != "placeholder"
        );
      }
      return true;
    } catch (e: any) {
      return false;
    }
  }

  return (
    <Flex alignCenter style={{ gap: "10px" }}>
      <Flex column style={{ cursor: "pointer" }}>
        <Text color="gainsboro">Classifying </Text>
        <Text
          color={isHovered ? "#3f51b5" : "white"}
          customFontSize={1}
          customFontWeight={700}
          onClick={() => {
            setBackToPageTrigger(backToPageTrigger + 1);
          }}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
        >
          {packetData.page_index != 0
            ? `Page ${packetData.page_index}`
            : "Document"}
        </Text>
      </Flex>
      {isSkippable && <Button onClick={() => onSaveSuccess()}>Skip</Button>}
      <FormControl
        variant="filled"
        sx={{
          minWidth: 120,
          background: "white",
          borderRadius: "30px",
          height: "45px",
          m: 0.5,
        }}
      >
        {!typeOptions.length && !searchKey ? (
          <Flex
            alignCenter
            justifyCenter
            style={{ height: "100%", width: "250px", gap: "10px" }}
          >
            <CircularProgress
              style={{
                color: "gray",
                width: "20px",
                height: "20px",
              }}
            />
          </Flex>
        ) : (
          <Select
            onChange={(e) => {
              setSelectedType(e.target.value);
            }}
            value={selectedType}
            disableUnderline={true}
            onClose={() => setSearchKey("")}
            sx={{
              height: "45px",
              background: "white",
              borderRadius: "30px",
              width: "250px",
              paddingLeft: "10px",
              paddingBottom: "15px",
            }}
          >
            <ListSubheader
              style={{
                paddingRight: "10px",
                paddingLeft: "10px",
                marginBottom: "10px",
              }}
            >
              <SearchTypeField
                searchKey={searchKey}
                setSearchKey={setSearchKey}
              />
            </ListSubheader>
            <MenuItem key={-1} value="placeholder" style={{ display: "none" }}>
              Select item type
            </MenuItem>
            {displayedOptions.length ? (
              displayedOptions.map((option: any, index) => (
                <MenuItem
                  key={index}
                  value={option.type_id}
                  style={{
                    display: option.type_id == selectedType ? "none" : "block",
                  }}
                >
                  {option.code == "None" || option.code == null
                    ? option.name
                    : `${option.code} - ${option.name}`}
                </MenuItem>
              ))
            ) : (
              <Flex justifyCenter alignCenter style={{ padding: "10px" }}>
                <Text color="gray"> No type has matched your search</Text>
              </Flex>
            )}
          </Select>
        )}
      </FormControl>
      {selectedType != initialType && (
        <Flex alignCenter>
          <Button
            size="large"
            variant="contained"
            sx={{
              marginLeft: "10px",
              height: "45px",
              display: isSaving && doingAction != "save" ? "none" : "block",
            }}
            onClick={() => perform("save")}
          >
            {isSaving && doingAction == "save" ? (
              <CircularProgress
                style={{
                  color: "white",
                  width: "20px",
                  height: "20px",
                }}
              />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            size="large"
            variant="contained"
            color="success"
            sx={{
              marginLeft: "10px",
              height: "45px",
              display: isSaving && doingAction != "submit" ? "none" : "block",
            }}
            onClick={() => perform("submit")}
          >
            {isSaving && doingAction == "submit" ? (
              <CircularProgress
                style={{
                  color: "white",
                  width: "20px",
                  height: "20px",
                }}
              />
            ) : (
              "Save & Submit"
            )}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default ItemTypeSelector;
