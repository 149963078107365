import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { TableContainer, TableHead } from "@mui/material";
import { IDocument } from "../../../../@types/Document";
import DocumentsTableItem from "./Item";
import Flex from "styled-flex-component";

interface IDocumentsTableProps {
  documents: IDocument[];
  refetch: () => void;
  grab: (doc_id: string, page_index: string, page_id: string) => void;
  unassign: (assignment_id: string, parent_assignment_id: string) => void;
  isUnassigned: boolean;
  isAssignedToOthers: boolean;
}

const DocumentsTable = ({
  documents,
  grab,
  unassign,
  isUnassigned,
  isAssignedToOthers,
  refetch,
}: IDocumentsTableProps) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <Flex
            alignCenter
            style={{
              height: "60px",
              borderBottom: "1px solid whitesmoke",
              fontWeight: "bold",
            }}
          >
            <div
              style={{
                width: isUnassigned ? "28vw" : "20vw",
              }}
            >
              Packet Name
            </div>
            <div
              style={{
                width: isUnassigned ? "20vw" : "14vw",
              }}
            >
              Packet Type
            </div>
            <div
              style={{
                width: isUnassigned ? "14vw" : "13vw",
              }}
            >
              Date Uploaded
            </div>
            <div style={{ width: "12vw" }}>Status</div>
            {isAssignedToOthers && (
              <div style={{ width: "12vw" }}>Assignee</div>
            )}
          </Flex>
        </TableHead>
        <TableBody>
          {documents.map((document: IDocument, index) => (
            <DocumentsTableItem
              key={index}
              document={document}
              index={index}
              grab={(doc_id, page_index, page_id) =>
                grab(doc_id, page_index, page_id)
              }
              unassign={(assignment_id, parent_assignment_id) =>
                unassign(assignment_id, parent_assignment_id)
              }
              isUnassigned={isUnassigned}
              isAssignedToOthers={isAssignedToOthers}
              refetch={() => refetch()}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentsTable;
