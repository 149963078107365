import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  CircularProgress,
} from "@mui/material";
import { Text } from "../../../UI/Typography";
import { useNavigate } from "react-router-dom";
import DocumentTypeChip from "../../../UI/DocumentTypeChip/DocumentTypeChip";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Flex from "styled-flex-component";
import Container from "../../../UI/Common/Container";
import { isEmpty, capitalize } from "lodash";
import { submit } from "../../../../api/grab.api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import GrabAllButton from "../../GrabAllButton";
import UnassignAllButton from "../../UnassignAllButton";
import SubmitAllButton from "../../SubmitAllButton";

interface Props {
  document: any;
  index: number;
  grab: (doc_id: string, page_index: string, page_id: string) => void;
  unassign: (assignment_id: string, parent_assignment_id: string) => void;
  isUnassigned: boolean;
  isAssignedToOthers: boolean;
  refetch: () => void;
}

export default function DocumentsTableItem({
  document,
  index,
  grab,
  unassign,
  isUnassigned,
  isAssignedToOthers,
  refetch,
}: Props) {
  const navigate = useNavigate();
  const [savingId, setSavingId] = useState("");
  const [grabbingIndex, setGrabbingIndex] = useState("");
  const [grabbingIndexDocId, setGrabbingIndexDocId] = useState("");
  const [unassignedPages, setUnassignedPages] = useState([]);
  const [submittedPages, setSubmittedPages] = useState([]);

  useEffect(() => {
    if (!document.pages) return;

    const unassigned = document.pages.filter((page) => page.page_type == null);
    const submitted = document.pages.filter((page) => page.page_type != null);
    setUnassignedPages(unassigned);
    setSubmittedPages(submitted);
  }, [document.pages]);

  async function updateStatus(assignment_id) {
    try {
      setSavingId(assignment_id);
      await submit(assignment_id);
      toast.success(`Task ${assignment_id} submitted successfully`);
      refetch();
      setSavingId("");
    } catch (e: any) {
      toast.error(e?.detail ?? "Something went wrong");
      setSavingId("");
    }
  }

  const submitButton = (assignment_id) => {
    return (
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          updateStatus(assignment_id);
        }}
        style={{ width: "150px" }}
        disabled={savingId == assignment_id}
      >
        {savingId == assignment_id ? (
          <CircularProgress
            style={{
              color: "#3f51b5",
              width: "20px",
              height: "20px",
            }}
          />
        ) : (
          "Submit"
        )}
      </Button>
    );
  };

  const row = (hasChild) => {
    return (
      <Flex
        justifyBetween
        alignCenter
        style={{
          paddingTop: "5px",
          paddingBottom: "5px",
          width: "100%",
        }}
      >
        <Flex style={{ width: "100%" }} alignCenter>
          {/* Packet ID */}
          <div
            style={{
              width: isUnassigned ? "28vw" : "20vw",
              display: "flex",
            }}
          >
            <Container wordBreak style={{ maxWidth: "90%" }}>
              <Text customFontSize={0.9}>
                {document.doc_name ?? document.doc_id}
              </Text>
              {document.pages && (
                <Badge
                  badgeContent={document.pages.length}
                  style={{ width: "30px" }}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "black",
                      backgroundColor: "lightgray",
                      border: "1px solid lightgray",
                    },
                  }}
                />
              )}
            </Container>
          </div>
          {/* Packet Type */}
          <div
            style={{
              width: isUnassigned ? "20vw" : "14vw",
            }}
          >
            {((isUnassigned && hasChild) ||
              document.doc_type ||
              document.document_type) && (
              <DocumentTypeChip
                label={
                  !hasChild
                    ? document.doc_type || document.document_type
                    : "Pages"
                }
                isPrimary={
                  !hasChild && (document.doc_type || document.document_type)
                }
                maxWidth="18vw"
              />
            )}
          </div>
          {/* Created At */}
          <div
            style={{
              width: "8vw",
            }}
          >
            <Text>{moment(document.created_at).format("LLL")} </Text>
          </div>
          {/* Status */}
          <div
            style={{
              width: "12vw",
              color:
                document.status != "pending" && !isUnassigned
                  ? "green"
                  : "black",
              textAlign: "center",
            }}
          >
            {(!hasChild || isUnassigned) && (
              <Text>
                {capitalize(
                  document.status == "classified_annotated"
                    ? "annotated"
                    : document.status
                )}
              </Text>
            )}
            {isUnassigned && (
              <Text>
                For{" "}
                {hasChild
                  ? "Classification"
                  : `Annotation ${!document.doc_type ? "& Classification" : ""}`}
              </Text>
            )}
          </div>
          {/* Assignee */}
          {isAssignedToOthers && !hasChild && <Text>{document.user_id}</Text>}
        </Flex>
        {/* Actions */}
        {!isAssignedToOthers ? (
          <Flex
            justifyEnd
            style={{
              width: hasChild ? (isUnassigned ? "17.5vw" : "29vw") : "18.5vw",
            }}
          >
            {hasChild ? (
              <Flex style={{ paddingRight: "15px" }}>
                {isUnassigned ? (
                  <GrabAllButton
                    document={document}
                    grabPage={(doc_id, page_index, page_id) =>
                      grab(doc_id, page_index, page_id)
                    }
                  />
                ) : (
                  <Flex style={{ gap: "10px" }} alignCenter>
                    {!isEmpty(unassignedPages) && (
                      <UnassignAllButton
                        document={document}
                        pages={unassignedPages}
                        unassignPage={(assignment_id, parent_assignment_id) =>
                          unassign(assignment_id, parent_assignment_id)
                        }
                      />
                    )}
                    {!isEmpty(submittedPages) && (
                      <SubmitAllButton
                        pages={submittedPages}
                        submitPage={(assignment_id) =>
                          updateStatus(assignment_id)
                        }
                      />
                    )}
                  </Flex>
                )}
              </Flex>
            ) : isUnassigned ? (
              grabButton()
            ) : (
              <Flex justifyEnd style={{ gap: "10px" }}>
                {/* Unassign */}
                {document.status == "pending" && unassignButton()}
                {/* Classify */}
                {classifyButton(
                  document.document_type != null,
                  (document.document_type != null &&
                    (document.status == "classified" ||
                      document.status == "classified_annotated")) ||
                    document.document_type == null
                )}
                {/* Annotate */}
                {annotateButton()}
                {/* Submit */}
                {document.status != "pending" &&
                  submitButton(document.assignment_id)}
              </Flex>
            )}
          </Flex>
        ) : (
          viewButton()
        )}
      </Flex>
    );
  };

  const grabButton = () => {
    return (
      <Button
        variant="text"
        style={{ width: "100px" }}
        onClick={() => {
          grab(document.doc_id, "", "");
        }}
      >
        Grab
      </Button>
    );
  };

  const unassignButton = () => {
    return (
      <Button
        variant="text"
        onClick={() => {
          unassign(document.assignment_id, "");
        }}
      >
        Unassign
      </Button>
    );
  };

  const annotateButton = () => {
    return (
      <Button
        variant="outlined"
        style={{ width: "150px" }}
        onClick={() => {
          navigate(`/insights/${document.doc_id}`, {
            state: {
              packetData: {
                ...document,
              },
            },
          });
        }}
      >
        Annotate
      </Button>
    );
  };

  const viewButton = () => {
    return (
      <Button
        variant="outlined"
        style={{
          width: "150px",
          marginRight: document?.pages?.length ? "15px" : "40px",
        }}
        onClick={() => {
          navigate(`/insights-readonly/${document.doc_id}`);
        }}
      >
        View
      </Button>
    );
  };

  const classifyButton = (started, isShow) => {
    if (!isShow) return;
    return (
      <Button
        variant="outlined"
        color="info"
        style={{ width: "150px" }}
        onClick={() => {
          navigate(`type/${document.doc_id}`, {
            state: {
              packetData: {
                ...document,
                page_index: 0,
                page_id: null,
              },
            },
          });
        }}
      >
        {started ? "Reclassify" : "Classify"}
      </Button>
    );
  };

  return (
    <div key={index}>
      {isEmpty(document.pages) ||
      (document.pages[0].page_id == null && isEmpty(document.pages)) ? (
        <>{row(false)}</>
      ) : (
        <>
          <Accordion elevation={0} key={document.doc_id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ padding: 0, height: "60px" }}
            >
              {row(true)}
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              {document.pages.map((page, index) => (
                <Flex
                  alignCenter
                  key={page.page_index}
                  style={{
                    background: page.page_type
                      ? "#e8f5e9"
                      : index % 2 == 0
                        ? "whitesmoke"
                        : "white",
                    height: "60px",
                    paddingLeft: 20,
                    justifyContent: isAssignedToOthers
                      ? "flex-start"
                      : "space-between",
                  }}
                >
                  {/* Page Details */}
                  <Flex
                    justifyBetween
                    style={{
                      width: "57vw",
                    }}
                  >
                    <Flex justifyBetween style={{ width: "64%" }}>
                      <Text customFontSize={1}>Page {page.page_index}</Text>
                      {page.page_type && (
                        <div style={{ width: "14vw" }}>
                          <DocumentTypeChip
                            label={page.page_type}
                            isPrimary={true}
                          />
                        </div>
                      )}
                    </Flex>
                    <Flex
                      style={{
                        width: "12vw",
                        color: page.status != "pending" ? "green" : "black",
                      }}
                    >
                      <Text style={{ textAlign: "center", width: "100%" }}>
                        {capitalize(page.status)}
                      </Text>
                    </Flex>
                  </Flex>
                  {isAssignedToOthers && <Text>{page.user_id}</Text>}
                  {/* Actions */}
                  {!isAssignedToOthers && (
                    <Flex>
                      <Flex
                        justifyEnd
                        style={{ gap: "20px", width: "18.5vw" }}
                        key={index}
                      >
                        {page.status == "classified" ? (
                          <Flex style={{ gap: "10px" }}>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                navigate(`type/${document.doc_id}`, {
                                  state: {
                                    packetData: page,
                                  },
                                });
                              }}
                              style={{ width: "150px" }}
                            >
                              {/* Reclassify */}
                              Reclassify
                            </Button>
                            {submitButton(page.assignment_id)}
                          </Flex>
                        ) : (
                          <Flex
                            justifyEnd
                            style={{ gap: "20px", width: "18.5vw" }}
                          >
                            {!isUnassigned && (
                              <Button
                                variant="text"
                                onClick={() => {
                                  if (
                                    document.doc_id == grabbingIndexDocId &&
                                    page.page_index == grabbingIndex
                                  ) {
                                    setGrabbingIndex("");
                                    setGrabbingIndexDocId("");
                                  }
                                  unassign(
                                    page.assignment_id,
                                    document.assignment_id
                                  );
                                }}
                              >
                                Unassign
                              </Button>
                            )}
                            <Button
                              variant={isUnassigned ? "text" : "outlined"}
                              style={{ width: "150px" }}
                              disabled={
                                isUnassigned &&
                                grabbingIndex == page.page_index &&
                                grabbingIndexDocId == document.doc_id
                              }
                              onClick={() => {
                                if (isUnassigned) {
                                  setGrabbingIndex(page.page_index);
                                  setGrabbingIndexDocId(document.doc_id);
                                  grab(
                                    document.doc_id,
                                    page.page_index,
                                    page.page_id
                                  );
                                } else
                                  navigate(`type/${document.doc_id}`, {
                                    state: {
                                      packetData: {
                                        ...page,
                                        parentDoc: document,
                                      },
                                    },
                                  });
                              }}
                            >
                              {isUnassigned ? (
                                grabbingIndex == page.page_index &&
                                grabbingIndexDocId == document.doc_id ? (
                                  <CircularProgress
                                    style={{
                                      color: "#3f51b5",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                ) : (
                                  "Grab"
                                )
                              ) : (
                                "Classify"
                              )}
                            </Button>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              ))}
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  );
}
