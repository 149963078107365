import { supabase } from "../utils/supabase";
import lithClient from "./instance";

export function fetchPagesForExport() {
  return supabase
    .from("export_pages")
    .select()
    .then((result: any) => {
      if (result.data) {
        const data = result.data;
        return transform(data);
      } else {
        return [];
      }
    }) as Promise<any[]>;
}

const transform = (data) => {
  const grouped = groupBy(data, "doc_id");
  const parents = Object.keys(grouped);
  const pageGroups = parents.map((parent) => {
    return {
      doc_id: parent,
      doc_name: grouped[parent][0].doc_name,
      pages: grouped[parent],
    };
  });
  return pageGroups;
};

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function exportPages(doc_ids) {
  return lithClient
    .post(`/export/docs`, doc_ids)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}

export function fetchDocumentsForExport() {
  return lithClient
    .get(`/documents`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}

export function exportAnnotations(doc_ids) {
  return lithClient
    .post(`/export/docs/annotations`, doc_ids)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}
