import { IDocument } from "../@types/Document";
import { supabase } from "../utils/supabase";
import _ from "lodash";
import lithClient from "./instance";
import { getUserSessionData } from "../utils/userSession";

export function getUnassignedDocuments() {
  return supabase
    .from("unassigned_documents")
    .select()
    .then((result: any) =>
      result.data ? sortByDate(result.data) : []
    ) as Promise<any[]>;
}

export function getUnassignedPages() {
  return supabase
    .from("found_pages")
    .select()
    .then((result: any) =>
      result.data ? getMissingPages(result.data) : []
    ) as Promise<any[]>;
}

export function getFoundPages() {
  return supabase
    .from("found_pages")
    .select()
    .then((result: any) => result.data) as Promise<any[]>;
}

export function getAssigned() {
  const session = getUserSessionData();
  return supabase
    .from("assigned_documents")
    .select()
    .eq("user_id", session.user.id)
    .not("status", "in", "(submit)")
    .then((result: any) => {
      if (result.data) {
        const data = result.data;
        const transformed = transform(data);
        return { total: data?.length, transformed };
      } else {
        return { total: 0, transformed: [] };
      }
    }) as Promise<IDocument[]>;
}

export function getAssignedToOthers() {
  const session = getUserSessionData();
  return supabase
    .from("assigned_documents")
    .select()
    .not("user_id", "in", `(${session.user.id})`)
    .not("status", "in", "(submit)")
    .then((result: any) => {
      if (result.data) {
        const data = result.data;
        const transformed = transform(data);
        return { total: data?.length, transformed };
      } else {
        return { total: 0, transformed: [] };
      }
    }) as Promise<IDocument[]>;
}

export function getDocumentClauses() {
  return lithClient
    .get(`/document/clauses`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}

const transform = (data) => {
  const grouped = groupBy(data, "doc_id");
  const parents = Object.keys(grouped);
  const doubleActionsParent: any = [];

  const finalData = parents.map((parent) => {
    const parentDetails = _.clone(data).find(
      (doc) => doc.document_id == parent || doc.doc_id == parent
    );
    const clone = _.clone(parentDetails);
    delete clone.page_index;
    delete clone.page_id;

    //map pages
    let pages: any = [];
    const parentDoc = grouped[parent].find((page) => page.page_id == null);
    if (parentDoc && grouped[parent].length > 1) {
      doubleActionsParent.push(parentDoc);
      pages = grouped[parent].filter((page) => page.page_id != null);
      delete clone.assignment_id;
      clone.assignment_id = pages[0].assignment_id;
    } else {
      pages = grouped[parent][0].page_id == null ? [] : grouped[parent];
    }

    // sort pages alphabetically
    if (pages) {
      pages.sort((a, b) => a.page_index - b.page_index);
    }

    //create transformed data
    return {
      ...clone,
      pages: pages,
    };
  });

  doubleActionsParent.forEach((parent) => {
    const parentDetails = _.clone(data).find(
      (item) => item.assignment_id == parent.assignment_id
    );

    const clone = _.clone(parentDetails);
    delete clone.page_index;
    delete clone.page_id;
    finalData.push({
      ...clone,
      pages: [],
    });
  });

  //sort final list by date
  return sortByDate(finalData);
};

const getMissingPages = (data) => {
  const parents: any = data.filter((row) => row.status == "parent");

  const unassignedPages: any = [];
  parents.forEach((parentObject) => {
    const parent = parentObject.doc_id;

    const docPageCount = parentObject.page_count;
    const allPageIndex = Array.from(Array(docPageCount)).map((e, i) => i + 1);

    const foundPageIndexes =
      data
        .filter(
          (doc) =>
            doc.doc_id == parent &&
            doc.status != "parent" &&
            doc.status != "unassigned"
        )
        .map((page) => page.page_index) ?? [];
    const missingWithPage = data.filter(
      (doc) => doc.doc_id == parent && doc.status == "unassigned"
    );
    const missingPages: any = [];
    allPageIndex.forEach((pageIndex) => {
      if (!foundPageIndexes.includes(pageIndex)) {
        const existingPage = missingWithPage.filter(
          (missing) =>
            missing.page_index == pageIndex && missing.doc_id == parent
        );
        missingPages.push({
          page_index: pageIndex,
          page_id: existingPage.length ? existingPage[0].page_id : "",
        });
      }
    });

    if (missingPages.length) {
      unassignedPages.push({
        doc_id: parent,
        doc_name: parentObject.doc_name,
        pages: missingPages,
      });
    }
  });
  return unassignedPages;
};

const sortByDate = (data) => {
  return data.sort(
    (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at)
  );
};

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function createPage(doc_id, page_index) {
  return lithClient
    .post(`/documents/${doc_id}/pages`, { page_index: page_index })
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}

export function getDocument(doc_id) {
  return lithClient
    .get(`/documents/${doc_id}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}

export function getDocumentUrl(doc_id) {
  return lithClient
    .get(`/documents/${doc_id}/signed-url`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}
