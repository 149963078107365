import { Button } from "@mui/material";
import { Text } from "../../../UI/Typography";
import moment from "moment";
import Flex from "styled-flex-component";
import Container from "../../../UI/Common/Container";
import { useNavigate } from "react-router-dom";

interface Props {
  feedback: any;
}

export default function PageFeedbackRow({ feedback }: Props) {
  const navigate = useNavigate();
  return (
    <Flex justifyBetween alignCenter style={{ height: "65px", width: "100%" }}>
      <Flex style={{ width: "100%" }}>
        {/* Packet ID */}
        <div style={{ width: "24vw", display: "flex" }}>
          <Container wordBreak>
            <Text customFontSize={0.9}>{feedback.doc_name}</Text>
          </Container>
        </div>
        {/* Packet Type */}
        <div style={{ width: "18vw" }}>
          <Container wordBreak>
            <Text customFontSize={0.9}>{feedback.page.page_index}</Text>
          </Container>
        </div>
        {/* Suggested Date */}
        <div style={{ width: "18vw" }}>
          <Text>{moment(feedback.created_at).format("LLL")} </Text>
        </div>
        {/* Suggested By */}
        <div style={{ width: "12vw" }}>
          <Text>
            {feedback.user_email ? feedback.user_email : feedback.user_id}{" "}
          </Text>
        </div>
      </Flex>
      {/* Actions */}
      <Flex justifyEnd style={{ width: "18.5vw" }}>
        <Button
          onClick={() => {
            navigate(`/feedback-review/${feedback.doc_id}`, {
              state: {
                feedback,
              },
            });
          }}
        >
          Review
        </Button>
      </Flex>
    </Flex>
  );
}
