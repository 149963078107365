import { FormControlLabel, Switch } from "@mui/material";
import { Text } from "../Typography";

interface ITestDataToggleProps {
  isShowingTestData: boolean;
  setIsShowingTestData: (isShowingTestData: boolean) => void;
}

const TestDataToggle = ({
  isShowingTestData,
  setIsShowingTestData,
}: ITestDataToggleProps) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={isShowingTestData}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setIsShowingTestData(event.target.checked)
          }
        />
      }
      label={<Text color="gray">Show test files</Text>}
    />
  );
};

export default TestDataToggle;
